import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ModalType } from '../../../../../../../../../libraries/college-settings/src/lib/enum/modal-type.enum';
import { TermsModalComponent } from '@shared/modals/terms-modal/terms-modal.component';

@Injectable({
  providedIn: 'root',
})
export class TermsModalService {
  private modalConfig: MatDialogConfig = {
    hasBackdrop: true,
    panelClass: '',
    minWidth: 360,
    maxWidth: 800,
    backdropClass: 'modal-overlay',
  };

  constructor(private md: MatDialog) {}

  /* tslint:disable cyclomatic-complexity */
  showModal(modalType: ModalType, initialState: object = {}, modalOptions: object = {}): MatDialogRef<any> {
    this.modalConfig = { ...this.modalConfig, ...modalOptions };
    const options: MatDialogConfig = { ...initialState, ...this.modalConfig };

    switch (modalType) {
      case ModalType.TERMS:
        return this.md.open(TermsModalComponent, {
          ...options,
          ...{ panelClass: 'terms-modal' },
          disableClose: true
        });
      default: //@ts-ignore
        return;
    }
  }
}
