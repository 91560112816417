import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, inject,
  Inject,
  OnInit,
  SecurityContext,
  signal
} from '@angular/core';
import { CommonModule, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { TERMS$, TERMS_PROVIDERS } from '@core/providers/terms.providers';
import { TermsService } from '../../../services/terms/terms.service';
import { TermsInterface } from '@state/terms/interfaces/terms.interface';
import { TextEditorContentComponent } from 'libraries/text-editor';
import { TermsState } from '@state/terms/terms.state';
import { AuthState } from '@state/auth/auth.state';
import { NewLoaderComponent } from '@shared/components/loader/new-loader.component';
import { LoadersModule } from '@app/shared-modules/loaders/loaders.module';
import { AirLibraryModule } from '@shared/modules/air/air-library/air-library.module';
import { AuthRepository } from 'libraries/auth';
import { AuthResponseInterface } from '@state/auth/interfaces/auth-response.interface';
import { Route, Router } from '@angular/router';
import { pages } from 'libraries/college-pages';

@Component({
  selector: 'yeda-terms-modal',
  standalone: true,
  imports: [
    CommonModule,
    NgIf,
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
    MatIconModule,
    TextEditorContentComponent,
    NewLoaderComponent,
    LoadersModule,
    AirLibraryModule
  ],
  providers: [TERMS_PROVIDERS],
  templateUrl: './terms-modal.component.html',
  styleUrls: ['./terms-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
@UntilDestroy()
export class TermsModalComponent implements OnInit {
  terms?: string | null;
  isLoading = signal<boolean>(true);

  private readonly authRepository = inject(AuthRepository);

  constructor(
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    public termsService: TermsService,
    @Inject(TERMS$) readonly terms$: Observable<TermsInterface>,
    public termsState: TermsState,
    public authState: AuthState,
    public dialogRef: MatDialogRef<TermsModalComponent>,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.setTerms();
  }

  setTerms(): void {
    this.terms$.pipe(untilDestroyed(this)).subscribe((terms) => {
      this.terms = terms.current  // @ts-ignore
        ? Object.values(this.sanitizer.sanitize(SecurityContext.NONE,
          this.sanitizer.bypassSecurityTrustHtml(terms.current) as string))[0]
        : null;

      if (terms.current !== null && terms.current !== '') {
        this.isLoading.set(false);
      }
      this.cdr.markForCheck();
    });
  }

  accept(): void {
    this.termsState
      .accept(this.authState.user.id)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.termsState.patch({ status: { show: false, pending: false } });
        this.chengAuth().then();
        this.cdr.markForCheck();
        this.dialogRef.close();
      });
  }

  async chengAuth(): Promise<void> {
    const auth = this.authRepository.snapshot;
    auth.user.show_terms = false;
    this.authState.setState(auth as AuthResponseInterface).then();
  }

  cancel(): void {
    this.dialogRef.close();
    this.termsState.reset();
    this.authState.logout().then(() => {
      const courseMatch = this.router.url.match(/courses\/(.*?)\//);
      if (courseMatch && courseMatch[1]) {
        this.router.navigate([pages.courses, courseMatch[1], pages.about]).then();
      }
    });
  }
}
