<div class="dialog-header">
  <h2 mat-dialog-title>{{ 'terms.title' | translate }}</h2>
  @if (!authState.user.show_terms) {
    <button mat-button mat-dialog-close [mat-dialog-close]="true" class="modal__close-btn">
      <mat-icon>close</mat-icon>
    </button>
  }
</div>
<div mat-dialog-content class="modal__content">
  <yeda-inner-loader class="modal-loader" [isLoading]="isLoading()" [size]="40"></yeda-inner-loader>

  <div class="content-terms" *ngIf="terms">
    <yeda-text-editor-content [content]="terms | airReplaceVars: termsService.variables"></yeda-text-editor-content>
  </div>
</div>

@if (authState.loggedIn && authState.user.show_terms) {
  <div mat-dialog-actions>
    <button mat-button (click)="cancel()">{{ 'terms.button_cancel' | translate }}</button>
    <button mat-button (click)="accept()">{{ 'terms.button_accept' | translate }}</button>
  </div>
}
